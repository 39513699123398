import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api, getEventById, getEventInfosByEventId, getTicketTypesByEventId, getTicketTypeInfosByTicketTypeId, getTicketTypeNumberOfAvailableTickets } from "ticketino-api-client";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import InformationAndTariff from "./components/InformationAndTariff";
import imgIconProgress2 from '../img/icon-progress2.svg';
import imgIconProgressGrey from '../img/icon-progress-grey.svg';
import imgIconProgressTick from '../img/icon-progress-tick.svg';

import "../css/style.css";

const Step10_Tickets_Collection = () => {
    const [posId, setPosId] = useState(null);

    const [event, setEvent] = useState({});

    const [ticketTypes, setTicketTypes] = useState([]);
    const [unlockedTicketTypes, setUnlockedTicketTypes] = useState([]);
    const [isTicketTypesUnlocked, setIsTicketTypesUnlocked] = useState(false);
    const [notAllowedPromotionCodes, setNotAllowedPromotionCodes] = useState([]);

    const [orderId, setOrderId] = useState(0);
    const [order, setOrder] = useState({});

    const [eventIdSammlung, setEventIdSammlung] = useState(0);

    const [promotioncode, setPromotioncode] = useState("");
    const [promotioncodeAdded, setPromotioncodeAdded] = useState("");
    const [promotionError, setPromotionError] = useState("");
    const [isPromotioncodeApplied, setIsPromotioncodeApplied] = useState("");

    const [isValid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(true);

    let { language } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("it" || "IT"):
            languageId = 4;
            break;
        default:
            languageId = 1;
            language = "de";
            break;
    }

    let navigate = useNavigate();
    let baseUrl = process.env.REACT_APP_BASEURL_API;
    api.defaults.baseURL = baseUrl;

    const [resources, setResources] = useState({});

    useEffect(() => {

        setLoading(true);
        const loadToken = async () => {
            try {
                const res = await axios.get("form/token");
                axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;
                api.defaults.headers.common['Authorization'] = "Bearer " + res.data;
                await sessionStorage.setItem("token", res.data);
                requestFormSettings();
            } catch (error) {
                console.error(error);
            }
        };

        let currentOrderId = sessionStorage.getItem("OrderId");

        if (currentOrderId > 0) {
            setOrderId(currentOrderId);
        }

        const requestFormSettings = async () => {
            try {
                const res = await axios.get(`form/formsettings`);

                if (currentOrderId > 0) {
                    loadOrder(currentOrderId, true)
                } else {
                    startOrder(res.data.posId);
                }

                setNotAllowedPromotionCodes(res.data.notAllowedPromotionCodes);
                setPosId(res.data.posId)
                setEventIdSammlung(res.data.eventIdSammlung);
            } catch (error) {
                console.error(error);
            }
        };


        loadToken();
        setLoading(false);
    }, []);


    useEffect(() => {

        setLoading(true);

        const requestResources = async () => {
            try {
                const res = await axios.get(`form/resources/${language}`);
                setResources(res.data?.translation);
            } catch (error) {
                console.error(error);
            }
        };
        requestResources();
        setLoading(false);
    }, [language]);


    useEffect(() => {
        if (order && orderId > 0 && eventIdSammlung > 0) {
            //reload event when ticket quantity is selected
            //to display correct quantity in the dropdown
            loadEvent(eventIdSammlung, orderId)
        }
    }, [order, orderId]);

    const startOrder = async (posId) => {

        const order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId ?? 6585,
            abbreviation: "",
            paymentType: 1,
        };

        try {
            const res = await axios.post(`${baseUrl}/ShopBasket/Order`, order);

            await sessionStorage.setItem("OrderId", res.data.id);

            setOrderId(res.data.id);

            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    };

    const loadOrder = async (orderId, removeTickets) => {
        try {
            // Order
            let updatedOrder = await getOrderByOrderId(orderId);

            if (updatedOrder != null && updatedOrder.tickets != null && removeTickets) {

                let ticketTypeIdsToRemove = updatedOrder.tickets
                    .map(ticket => ticket.id);

                // DELETE request to remove tickets
                updatedOrder = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    data: { TicketsToRemove: ticketTypeIdsToRemove }
                });

                updatedOrder.tickets = [];
            }

            setOrder(updatedOrder);

            //validate page if user can proceed
            let quantity = 0;

            if (updatedOrder != null && updatedOrder.tickets != null) {
                quantity = updatedOrder.tickets.length;
            }

            if (quantity > 0) {
                setIsValid(true);
            } else {
                setIsValid(false);
            }
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/ShopBasket/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
            if (error.response.status === 404 || error.response.status === 403) {
                const newOrder = await startOrder(posId);
                return newOrder;
            }
        }
    }

    const loadEvent = async (eventId, orderId) => {
        try {
            setLoading(true);

            // Event
            const event = await getEventById(eventId);
            const infos = await getEventInfosByEventId(eventId);

            // sorting it according to the language
            let eventInfo = infos.eventInfos.find(info => info.languageId == languageId);

            if (!eventInfo || eventInfo === null) {
                eventInfo = infos.eventInfos.find(info => info.languageId == 0);
            }

            // setting the eventInfo object in the info attribute of event
            const updatedEventInfo = { ...event, info: eventInfo };

            // TicketType
            const ticketTypes = await getTicketTypesByEventId(eventId);

            const updatedTicketTypes = await Promise.all(
                ticketTypes.map(async (ticketType) => {
                    const info = await getTicketTypeInfosByTicketTypeId(ticketType.id);

                    // sorting it according to the language
                    const ticketTypeInfo = info.find(info => info.languageId == languageId) ?? info[0];

                    const ticketTypeAvailability = await getTicketTypeNumberOfAvailableTickets(ticketType.id, orderId);

                    // setting the ticketTypeInfo and ticketTypeAvailability object as attributes in ticketType (info and availability)
                    return { ...ticketType, info: ticketTypeInfo, availability: ticketTypeAvailability }
                })
            )

            // setting the values in the end
            setEvent(updatedEventInfo);
            setTicketTypes(updatedTicketTypes);
            setLoading(false);

        } catch (error) {
            console.error(error);
        }
    }

    const onTicketTypeChange = (e, ticketTypeId) => {
        setLoading(true);

        const ticketToAdd = {
            ticketTypeId: ticketTypeId,
            quantity: e.target.value,
        };

        handleTicketsFromBasket(ticketToAdd);
    };

    const handleTicketsFromBasket = async (ticketToAdd) => {

        if (order != null && order.tickets != null) {
            let ticketTypeIdsToRemove = order.tickets
                .filter(ticket => ticket.ticketTypeId === ticketToAdd.ticketTypeId)
                .map(ticket => ticket.id);

            // DELETE request to remove tickets
            await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                data: { TicketsToRemove: ticketTypeIdsToRemove }
            });
        }

        let addTicketTypes = [];
        addTicketTypes.push(ticketToAdd);

        addTicketTypes = addTicketTypes.map((att) => {
            if (unlockedTicketTypes.includes(att.ticketTypeId)) {
                att.promotionCode = promotioncodeAdded;
            }
            return att;
        })

        //google tag manager add or remove tickets to cart
        let ticketType = ticketTypes.find(tt => tt.id === ticketToAdd.ticketTypeId);
        if (ticketType && ticketToAdd) {

            let ticketsWithSameTicketTypeId = [];

            if (order.tickets) {
                ticketsWithSameTicketTypeId = order.tickets.filter(t => t.ticketTypeId === ticketToAdd.ticketTypeId)
            }

            if (ticketsWithSameTicketTypeId.length < ticketToAdd.quantity) {
                const quantity = ticketToAdd.quantity - ticketsWithSameTicketTypeId.length;
                
                // gtm - add_to_cart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'add_to_cart',
                    'items': [
                        {
                            'item_id': ticketType.id,
                            'item_name': event.info?.name,
                            'item_variant': ticketType.info?.name,
                            'price': ticketType.price,
                            'quantity': quantity
                        }
                    ]
                });
            } else {
                const quantity = ticketsWithSameTicketTypeId.length - ticketToAdd.quantity;

                // gtm - remove_from_cart
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'remove_from_cart',
                    'items': [
                        {
                            'item_id': ticketType.id,
                            'item_name': event.info?.name,
                            'item_variant': ticketType.info?.name,
                            'price': ticketType.price,
                            'quantity': quantity
                        }
                    ]
                });
            }
        }

        if (ticketToAdd.quantity > 0) {
            await axios
                .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                    ticketsToAdd: addTicketTypes,
                })
                .then(() => {
                    //// gtm - addToCart
                    //window.dataLayer = window.dataLayer || [];
                    //window.dataLayer.push({
                    //    'event': 'addToCart'
                    //});
                })
                .catch((error) => {
                    console.error(error.response.data);
                });
        }

        loadOrder(orderId, false);
    };

    const applyPromotionCodeToOrder = async (orderId, code) => {

        if (notAllowedPromotionCodes.includes(code.toLowerCase())) {
            setPromotionError(resources?.PromoCodeNotAllowed);
            return;
        }

        try {
            const response = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setIsPromotioncodeApplied(true);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(error.response.data);
            setIsPromotioncodeApplied(false);
        }
    }

    const deletePromotionCodeFromOrder = async (orderId, code) => {
        try {
            const response = await axios.delete(`${baseUrl}/ShopBasket/Order/${orderId}/PromotionCode/${code}`);
            setPromotioncode("");
            setPromotioncodeAdded("");
            setIsPromotioncodeApplied(false);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const getTicketTypeAvailability = async (ticketTypeId, orderId, promotionCode) => {
        try {
            const response = await axios.get(`${baseUrl}/TicketType/${ticketTypeId}/Availability?orderId=${orderId}&promotionCode=${promotionCode}`);
            return response.data;
        } catch (error) {
            console.error(error.response.data);
            setPromotionError(resources.detailPage?.errorMessage);
        }
    }

    const addPromotionToOrder = async () => {
        setPromotionError("");
        setLoading(true);

        const updatedUnlockedTicketTypes = await applyPromotionCodeToOrder(orderId, promotioncode);
        let updatedTicketTypes = [];

        updatedTicketTypes = await Promise.all(ticketTypes.map(async (ticketType) => {
            if (updatedUnlockedTicketTypes?.unlockedTicketTypes?.includes(ticketType.id)) {
                const unlockedTicketTypeAvailability = await getTicketTypeAvailability(ticketType.id, orderId, promotioncode);
                ticketType.availability = unlockedTicketTypeAvailability;
            }
            return ticketType;
        }))

        await sessionStorage.setItem("addedPromotion", promotioncode);
        setPromotioncodeAdded(promotioncode);

        setTicketTypes(updatedTicketTypes)
        setUnlockedTicketTypes(updatedUnlockedTicketTypes?.unlockedTicketTypes ?? []);
        setIsTicketTypesUnlocked(true);
        setLoading(false);
    }

    const removePromotionFromOrder = async () => {
        setPromotionError("");
        setLoading(true);

        await deletePromotionCodeFromOrder(orderId, promotioncode);
        await loadEvent(eventIdSammlung, orderId);

        setUnlockedTicketTypes([]);
        setIsTicketTypesUnlocked(false);
        setLoading(false);
    }

    const navigateToShipping = async () => {

        // gtm - begin_checkout
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'begin_checkout'
        });

        navigate(`/${language}/shipping`);
    }

    const mapTicketTypes = () => {
        let ticketTypesSorted = [];

        if (ticketTypes && ticketTypes.length > 0) {
            ticketTypesSorted = [...ticketTypes].sort((a, b) => a.sortOrder - b.sortOrder);
        }

        return (
            <div style={{ position: 'relative', opacity: loading ? 0.5 : 1, pointerEvents: loading ? 'none' : 'auto' }}>
                {ticketTypes &&
                    ticketTypes.length > 0 &&
                    ticketTypesSorted.map((tt, index) =>
                        (tt.availability?.availableTickets + (order.tickets?.filter((t) => t.ticketTypeId === tt.id)?.length ?? 0)) > 0 ? (
                            <div
                                key={index}
                                className="row pt-2 pb-2 padding-left-25 padding-right-25"
                                style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#EFF2F3' }}
                            >
                                <div className="col-6 col-md-7 text-16-18 black-color text-start d-flex align-items-center padding-0">
                                    <label>{tt.info?.name}</label>
                                </div>
                                <div className="col-1 col-md-2 text-16-18 black-color d-flex justify-content-end align-items-center">
                                    <label>{tt.currency}</label>
                                </div>
                                <div className="col-2 col-md-2 text-16-18 black-color d-flex justify-content-start align-items-center">
                                    <label className="price-label text-end">{tt.price.toFixed(2)}</label>
                                </div>
                                <div className="col-3 col-md-1 text-16-18 black-color d-flex justify-content-start align-items-center padding-0">
                                    <select
                                        className="form-select"
                                        value={order && order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0}
                                        onChange={(e) => onTicketTypeChange(e, tt.id)}
                                        disabled={loading}
                                    >
                                        {Array.from(
                                            Array(
                                                tt.availability?.availableTickets +
                                                1 +
                                                (order.tickets ? order.tickets.filter((t) => t.ticketTypeId === tt.id).length : 0)
                                            ),
                                            (e, i) => (
                                                <option key={i} value={i}>
                                                    {i}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                            </div>
                        ) : null
                    )}
                {loading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            zIndex: 10,
                        }}
                    >
                        <Loading bgColor="transparent" color="#d3d3d3" position="relative" />
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={true} pagePath="sammlung" />
            <main className="flex-fill">
                {resources && (
                    <div className="container page-wrapper">

                        <div className="progress-container">
                            <div className="progress-step active">
                                <div>
                                    <div className="image">
                                        <img src={imgIconProgressTick} alt="Step 1" />
                                    </div>
                                    <div className="step-number text-wrap visible-desktop">{resources?.DateAndTime}</div>
                                </div>
                                <div className="line"></div>
                            </div>

                            <div className="progress-step completed">
                                <div>
                                    <div className="image">
                                        <img src={imgIconProgress2} alt=" Step 2" />
                                    </div>
                                    <div className="step-number text-wrap visible-desktop">{resources?.TicketSelection}</div>
                                </div>
                                <div className="line"></div>
                            </div>

                            <div className="progress-step">
                                <div>
                                    <div className="image">
                                        <img src={imgIconProgressGrey} alt="Step 3" />
                                    </div>
                                    <div className="step-number text-wrap visible-desktop">{resources?.SummaryAndContact}</div>
                                </div>
                                <div className="line"></div>
                            </div>

                            <div className="progress-step">
                                <div>
                                    <div className="image">
                                        <img src={imgIconProgressGrey} alt="Step 4" />
                                    </div>
                                    <div className="step-number text-wrap visible-desktop">{resources?.PaymentAndDelivery}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5 sticky-wrapper">
                            <div className="col-md-8 padding-0">
                                <p className="text-50-55 black-color font-500">{resources?.Collection}</p>
                            </div>
                        </div>

                        <br />

                        {mapTicketTypes()}


                        <div className="row mt-5">
                            <div className="col-md-7 col-6 text-18-21 black-color d-flex justify-content-start align-items-center padding-0">{resources.Total}:</div>
                            <div className="col-1 col-md-2 text-18-21 black-color d-flex justify-content-end align-items-center">
                                <label className="font-600 padding-right-10">
                                    CHF
                                </label>
                            </div>
                            <div className="col-2 col-md-1 text-18-21 black-color d-flex justify-content-start align-items-center">
                                <label className="font-600 price-label text-end padding-right-10"> {order && order.total && order.total !== null ? (
                                    <span>{(order.total).toFixed(2)}</span>
                                ) : (
                                    <span>0.00</span>
                                )}
                                </label>
                            </div>
                        </div>

                        {(order && (order.tickets === undefined || order.tickets.length === 0)) && (
                            <div className="row mt-2">
                                <div className="col-md-7 col-6 text-18-21 red d-flex justify-content-start align-items-center padding-0">{resources?.TicketChoose}</div>
                            </div>
                        )}

                        <div className="row mt-5 mb-5">
                            <div className="col-6 d-flex justify-content-start align-items-center padding-0">
                                <button className="btn transperant-grey-button" onClick={() => navigate(-1)}>
                                    {resources?._Back}
                                </button>
                            </div>
                            <div className="col-6 d-flex justify-content-end align-items-center padding-0">
                                <button
                                    className="btn grey-button "
                                    disabled={!isValid}
                                    onClick={() => navigateToShipping()}
                                >
                                    {resources?.BookNow}
                                </button>
                            </div>
                        </div>
                        <InformationAndTariff />

                    </div>
                )}
            </main>
            <Footer />
        </div>
    );
};

export default Step10_Tickets_Collection;