import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { api, getTicketTypeById, getEventInfosByEventId, getTicketTypeInfosByTicketTypeId } from "ticketino-api-client";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

/*import logo from "../img/logo.png";*/
import pdfImage from "../img/pdf-icon.png";

const Step40_Confirmation = () => {
    const [resources, setResources] = useState({});
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");

    const [token] = useState(sessionStorage.getItem("token"));

    // fetching resources
    let { language } = useParams();
    const { orderId } = useParams();

    let languageId = 0;

    // changing languageId according to the url
    switch (language) {
        case ("de" || "DE"):
            languageId = 1;
            break;
        case ("en" || "EN"):
            languageId = 3;
            break;
        case ("fr" || "FR"):
            languageId = 2;
            break;
        default:
            languageId = 1;
            language = "de";
            break;
    }

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    let navigate = useNavigate();

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Authorization'] = "Bearer " + token;

        requestResources();
        requestFormSettings();
    }, [language]);

    useEffect(() => {

        sendGtmPurchase();
    }, [order]);

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data?.confirmation);
            })
            .catch((error) => console.log(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const order = await getOrderByOrderId(orderId);
            setOrder(order);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.log(error.response.data));
    };

    const sendGtmPurchase = async () => {
        let gtmPurchaseAlreadySent = await sessionStorage.getItem("gtmPurchaseAlreadySent" + order.id);

        if (order.tickets && gtmPurchaseAlreadySent !== "true") {
            const items = await Promise.all(order.tickets.map(async (ticket) => {
                const ticketType = await getTicketTypeById(ticket.ticketTypeId);

                const ticketTypeInfos = await getTicketTypeInfosByTicketTypeId(ticketType.id);
                let ticketTypeInfo = ticketTypeInfos.find(info => info.languageId === languageId);

                if (!ticketTypeInfo || ticketTypeInfo === null) {
                    ticketTypeInfo = ticketTypeInfos.find(info => info.languageId === 0);
                }

                const eventInfos = await getEventInfosByEventId(ticketType.eventId);
                let eventInfo = eventInfos.eventInfos.find(info => info.languageId === languageId);

                if (!eventInfo || eventInfo === null) {
                    eventInfo = eventInfos.eventInfos.find(info => info.languageId === 0);
                }

                return {
                    'item_id': ticket.id.toString(),
                    'item_name': eventInfo?.name,
                    'item_variant': ticketTypeInfo?.name,
                    'price': ticket.price,
                    'quantity': 1
                };
            }));

            console.log("items 1")
            console.log(items)

            // gtm - purchase
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'purchase',
                'items': items
            });

            await sessionStorage.setItem("gtmPurchaseAlreadySent" + order.id, "true");
        }
    };

    return (
        <div className="d-flex flex-column min-vh-100">
            <Navbar numberOfTickets={order?.tickets?.length ?? 0} isShoppingCartVisible={false} pagePath="confirmation" />
            <main className="flex-fill">
                <div className="container page-wrapper">
                    {resources && (
                        <div className="container page-wrapper">
                            <div className="row mt-5 mb-4">
                                <div className="col-12">
                                    <a href="https://www.kunsthaus.ch/" className="d-flex align-items-center text-decoration-none text-dark button-arrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M15 8a.5.5 0 0 1-.5.5H3.707l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L3.707 7.5H14.5A.5.5 0 0 1 15 8z" />
                                        </svg>
                                        <span className="ms-2">{resources?.ToKunsthausWebsite}</span>
                                    </a>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <div>
                                        <div className="row">
                                            <div className="col-md-12 mb-2">
                                                <p className="text-50-55 black-color font-500">
                                                    {resources?.ThankYouHeader}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <div className="row">
                                                <div className="col-md-12 ml-5 text-16-18 black-color">
                                                    <p>{resources?.YourOrderNumber} {order?.invoiceNumber && order?.invoiceNumber}</p>
                                                    <p className="col-md-12 ml-5 text-16-18 black-color"
                                                        dangerouslySetInnerHTML={{ __html: resources?.confirmationMessage }}
                                                    ></p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12 mt-4">
                                                    {/*<img src={pdfImage} className="pdf-icon" alt="Pdf"></img>*/}
                                                    <button className="grey-button form-control pdf-button"
                                                        onClick={() => getPdfTicketsOfOrder()}
                                                    >
                                                        {resources?.DownloadTicket}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="row mt-5 mb-4">
                                                <div className="col-6 d-flex justify-content-start align-items-center">
                                                    <button className="btn transperant-grey-button" onClick={() => navigate("/")}>
                                                        {resources?.Back}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mt-5 text-center">
                                        {resources.translation && (
                                            <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation.TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                        )}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Step40_Confirmation;
